import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {Link} from 'prismic-reactjs'; 
import linkResolver from '../utils/linkResolver'
import NewsletterForm from '../components/newsletterForm'

import './footer.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const Footer = () => {
  const data = useStaticQuery(graphql`
  {
    prismic {
      allFooters {
        edges {
          node {
            logo
            navigation {
              ... on PRISMIC_Navigation {
                title
                _linkType
                nav {
                  ... on PRISMIC_NavigationNavNav_item {
                    type
                    label
                    fields {
                      sub_nav_link {
                        _linkType
                        ... on PRISMIC_Post {
                          title
                          _meta {
                            uid
                            type
                            tags
                          }
                          _linkType
                        }
                        ... on PRISMIC_Page {
                          title
                          _linkType
                          _meta {
                            uid
                            tags
                            type
                          }
                        }
                        ... on PRISMIC__ExternalLink {
                          _linkType
                          url
                        }
                        ... on PRISMIC_Find_your_survey {
                          _linkType
                          _meta {
                            uid
                            type
                            tags
                          }
                          title
                        }
                        ... on PRISMIC_Pricing_page {
                          title
                          _linkType
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                      }
                      sub_nav_link_label
                    }
                    primary {
                      label
                      link {
                        _linkType
                      }
                    }
                  }
                }
              }
            }
            _linkType
          }
        }
      }
    }
  }
  
  `)

  function inputKeyPress(e){

    const hiddenForm = document.querySelector('._form_7');

    var element = e.target
    var textEntered = element.value;

    if(hiddenForm){
      if(element.placeholder === "Full Name"){
        hiddenForm.querySelector('input[name=fullname]').value = textEntered;
      }else if(element.placeholder === "Email"){
        hiddenForm.querySelector('input[name=email]').value = textEntered;
      }
    } 
  }

  function checkboxPress(e){

    const hiddenForm = document.querySelector('._form_7');

    var element = e.target;

    if(hiddenForm){
      if(element.name === "creator"){
        hiddenForm.querySelector('#field_20Creator').click();
      }else if(element.name === "merchant"){
        hiddenForm.querySelector('#field_20Merchant').click();
      }else if(element.name === "affiliate"){
        hiddenForm.querySelector('#field_20Affiliate').click();
      }else if(element.name === "backer"){
        hiddenForm.querySelector('#field_20Backer').click();
      }
    }
  }

  function newsletterSubmit(e){

    const hiddenForm = document.querySelector('._form_7');

    var nameEntered = document.querySelector('#kb__newsletter-full-name');
    var emailEntered = document.querySelector('#kb__newsletter-email');
    
    if(hiddenForm){
      if(nameEntered.value !== "" && emailEntered.value !== "" ){
        e.preventDefault();
        hiddenForm.querySelector('#_form_7_submit').click();

        var formSuccess = document.querySelector('.form-success');
        var signupForm = document.querySelector('.kb__newsletter');

        setTimeout(function(){ 
          signupForm.reset();
        }, 500);

        formSuccess.classList.add('form-success--active');
        
        setTimeout(function(){ 
          formSuccess.classList.remove('form-success--active');
        }, 5000);

      }
    }
  }

  const footerData = data.prismic.allFooters.edges[0].node;

  if(!footerData) return null;

  return (
    <footer className="kb__footer">
      <div className="kb__container--full">
        <img className="kb__footer-logo" src={footerData.logo.url} alt={footerData.logo.alt} />
        <div className="kb__grid">
          <div className="kb__col-6_sm-12">
            <div className="kb__grid kb__footer-menu">
              {footerData.navigation.nav.map((navList, index) => 
                <div className="kb__footer-nav-list kb__col-4_md-6" key={index}>
                  <nav>
                  
                    <h5>{navList.primary.label[0].text}</h5>  
                    
                    {navList.fields.map((links, i) =>
                      <a key={i} href={RichLink(links.sub_nav_link)}>{links.sub_nav_link_label[0].text}</a>
                    )}
                  </nav>
                </div>
              )}
            </div>
          </div>
          <div className="kb__col-6_sm-12">
            <div className="kb__grid-center">
              <div className="kb__col-8_sm-12">

                <NewsletterForm />

                <form className="kb__newsletter">
                  <h5>Newsletter</h5>
                  <p>Enter your info to subscribe to our newsletter:</p>

                  <div className="form-success">
                    <span>Thanks for signing up! <span role="img" aria-label="emoji hand waving">👋</span></span>
                  </div>
                
                  <input id="kb__newsletter-full-name" type="text" required placeholder="Full Name" aria-label="Newsletter signup please enter your full name" onChange={inputKeyPress} />
                  
                  <input id="kb__newsletter-email" type="email" placeholder="Email" required aria-label="Newsletter signup please enter your full name" onChange={inputKeyPress} />
                  
                  <p className="kb__body-sm">Let us know who you are so we can send you better content (check all that apply):</p>
                  
                  <div className="kb__grid">
                    <div className="kb__col-6">
                      <div className="kb__form-field">
                        <label htmlFor="creator">
                          <div className="kb__checkbox-container">
                            <input type="checkbox" id="creator" name="creator" aria-label="Are you a creator?" onChange={checkboxPress}/>
                            <div className="kb__checkbox"></div>
                          </div>
                          Creator
                        </label>
                      </div>
                    </div>
                    <div className="kb__col-6">
                      <div className="kb__form-field">
                        <label htmlFor="backer">
                          <div className="kb__checkbox-container">
                            <input type="checkbox" id="backer" name="backer" aria-label="Are you a backer?" onChange={checkboxPress}/>
                            <div className="kb__checkbox"></div>
                          </div>
                          Backer
                        </label>
                      </div>
                    </div>
                    <div className="kb__col-6">
                      <div className="kb__form-field">
                        <label htmlFor="merchant">
                          <div className="kb__checkbox-container">
                            <input type="checkbox" id="merchant" name="merchant" aria-label="Are you a merchant?" onChange={checkboxPress}/>
                            <div className="kb__checkbox"></div>
                          </div>
                          Merchant
                        </label>
                      </div>
                    </div>
                    <div className="kb__col-6">
                      <div className="kb__form-field">
                        <label htmlFor="affiliate">
                          <div className="kb__checkbox-container">
                            <input type="checkbox" id="affiliate" name="affiliate" aria-label="Are you an affiliate?" onChange={checkboxPress}/>
                            <div className="kb__checkbox"></div>
                          </div>
                          Affiliate
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="kb__button--primary" type="submit" onClick={newsletterSubmit}>Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
