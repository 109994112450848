import React from 'react';
import Cookie from 'js-cookie'

class LoginActions extends React.Component {

  constructor (props) { 
    super(props);

    this.state = { 
      loggedIn: Cookie.get('user_credentials')
    };
  }

  render() {

    var buttonClass= "";
    if(this.props.location === "mobile"){
      buttonClass = "kb__mobile-nav-link"
    }else{
      buttonClass = "kb__button"
    }

    return (
      <div className="kb__header-actions">
          
          {typeof this.state.loggedIn !== "undefined"
            ? (
                <a className="kb__button--primary" href="/login" title="Go to your dashboard">Dashboard</a>
              )
            : (
              <>
                <a tabIndex="0" className={buttonClass} href="/login" title="Login to your dashboard">Log In</a>
                <a className="kb__button--primary" href="/register" title="Sign up to start using Kickbooster">Sign up</a>
              </>
            )
          }
        
      </div>
    )
  }
}

export default LoginActions;
