import React from "react"
import { useStaticQuery, graphql} from "gatsby"
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import LoginActions from './loginActions'

import iconKickburger from '../images/icon-kickburger.svg'
import iconClose from '../images/icon-close.svg'
import iconChevron from '../images/icon-chevron.svg'

import './header.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const Header = () => {

  const data = useStaticQuery(graphql`
  {
    prismic {
      allHeaders {
        edges {
          node {
            mobile_logo
            navigation {
              _linkType
              ... on PRISMIC_Navigation {
                title
                _linkType
                nav {
                  ... on PRISMIC_NavigationNavNav_item {
                    type
                    label
                    fields {
                      sub_nav_link {
                        _linkType
                        ... on PRISMIC_Post {
                          title
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            type
                            uid
                            tags
                          }
                        }
                        ... on PRISMIC_Career {
                          title
                          _meta {
                            type
                            uid
                            tags
                          }
                        }
                        ... on PRISMIC_Homepage {
                          title
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                        ... on PRISMIC__ExternalLink {
                          _linkType
                          url
                        }
                        ... on PRISMIC_Pricing_page {
                          _linkType
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                        ... on PRISMIC_Find_your_survey {
                          title
                          meta_title
                          _meta {
                            uid
                            type
                            tags
                          }
                          _linkType
                        }
                      }
                      sub_nav_link_label
                    }
                    primary {
                      label
                      link {
                        ... on PRISMIC_Post {
                          title
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                        _linkType
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                            type
                            tags
                          }
                        }
                        ... on PRISMIC__ExternalLink {
                          _linkType
                          url
                        }
                        ... on PRISMIC_Homepage {
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
            logo
          }
        }
      }
    }
  }  

  `)


  function dropdownHide(e){

    if(e.target.classList.contains('kb__button') || e.target.classList.contains('kb__dropdown') || e.target.classList.contains('kb__dropdown-link') ){
    } else {

      var prevActive = document.querySelector('.kb__dropdown--active');

      if(prevActive){
        prevActive.classList.remove('kb__dropdown--active');
        document.removeEventListener('mousemove', dropdownHide, true);
      }
    }
  }

  function dropdownToggle(e){
    var dropdown = e.target.parentElement;

    var prevActive = document.querySelector('.kb__dropdown--active');

    if(prevActive){
      prevActive.classList.remove('kb__dropdown--active');
    }

    if(dropdown.classList.contains('kb__dropdown--active')){
      dropdown.classList.remove('kb__dropdown--active');
    }else{
      dropdown.classList.add('kb__dropdown--active');
      document.addEventListener('mousemove', dropdownHide, true);
    }
  }
  
  function mobileMenuOpen(){
    var body = document.querySelector('body');
    body.classList.add('kb__mobile-menu--show');
  }
  
  function mobileMenuClose(){
    var body = document.querySelector('body');
    body.classList.remove('kb__mobile-menu--show');
  }

  function accordionToggle(e){
    var menuClicked = e.target;

    if (menuClicked.parentElement.classList.contains('kb__mobile-nav-group--active')){
      menuClicked.parentElement.classList.remove('kb__mobile-nav-group--active');
    }else{
      menuClicked.parentElement.classList.add('kb__mobile-nav-group--active');
    }
  }


  const headerData = data.prismic.allHeaders.edges[0].node;

  if(!headerData) return null;

  return (
    <header className="kb__header">

      <div className="kb__container kb__container--full">

        <a className="kb__logo" href="/">
          <img className="kb__logo--desktop" src={headerData.logo.url} alt="" />
          <img className="kb__logo--mobile" src={headerData.mobile_logo.url} alt="" />
        </a>

        <nav className="kb__main-menu">
          {headerData.navigation.nav &&
            headerData.navigation.nav.map((navList, index) => {

              if(navList.fields.length > 2){
                return(
                  <span key={index} className="kb__dropdown-container" data-dropdown-id={index}>
                    <button tabIndex="0" className="kb__button" onMouseEnter={dropdownToggle} onClick={dropdownToggle} data-has-icon data-has-subnav>{RichText.asText(navList.primary.label)}</button>

                    <div className="kb__dropdown">
                      {navList.fields.map((links, i) =>
                        <a key={i} className="kb__dropdown-link" href={RichLink(links.sub_nav_link)} title={RichText.asText(links.sub_nav_link_label)}>{RichText.asText(links.sub_nav_link_label)}</a>
                      )}
                      </div>
                  </span>
                )
              } else {
                return(
                  <a key={index} className="kb__button" href={RichLink(navList.primary.link)} title={RichText.asText(navList.primary.label)}>{RichText.asText(navList.primary.label)}</a>
                )
              }


            })
          }

          <LoginActions location="desktop" />

        </nav>

        <button className="kb__button kb__kickburger" onClick={mobileMenuOpen} aria-label="Mobile Menu toggle">
          <img src={iconKickburger} alt="Main Mobile Navigation Icon" />
        </button>
      </div>


      <div className="kb__mobile-menu">
        <div className="kb__mobile-menu-header">
          
          <LoginActions location="mobile" />
          
          <button className="kb__kickburger-icon--close kb__button" onClick={mobileMenuClose}>
            <img src={iconClose} alt="Close Menu Icon"/>
          </button> 
        </div>

        <nav className="kb__mobile-nav">

        {headerData.navigation.nav &&

          headerData.navigation.nav.map((navList, index) => {

            if(navList.fields.length > 2){
              return(
                <div key={index} className="kb__mobile-nav-group">
                  <button className="kb__mobile-accordion-trigger" onClick={accordionToggle}>{RichText.asText(navList.primary.label)} <img src={iconChevron} alt="Icon Chevron"/></button>
                  <div className="kb__mobile-accordion-body">
      
                      {navList.fields.map((links, i) =>
                        <a key={i} className="kb__mobile-nav-link" href={RichLink(links.sub_nav_link)} onClick={mobileMenuClose}>{links.sub_nav_link_label[0].text}</a>
                      )}
                  </div>
                </div>
              )
            } else {
              return(
                <a key={index} className="kb__mobile-nav-link" href={RichLink(navList.primary.link)}>{RichText.asText(navList.primary.label)}</a>
              )
            }
            
          })
        }

        </nav>
      </div>


    </header>
  )
}

export default Header
