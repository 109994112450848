const linkResolver = (doc) => {

  if(doc._meta){
    // Pretty URLs for known types
    if (doc._meta.type === 'post') return `/blog/${doc._meta.uid}/`
    if (doc._meta.type === 'find_your_survey') return `/${doc._meta.uid}/`
    if (doc._meta.uid === "creators-corner" || doc._meta.uid === "kickbooster-news" || doc._meta.uid === "ecommerce-tips" || doc._meta.uid === "crowdfunding-tips" || doc.uid === "case-studies" || doc.uid === "featured-campaigns") return `/blog/${doc._meta.uid}/`
    if (doc._meta.type === 'page') return `/${doc._meta.uid}/`
    if (doc._meta.type === 'success_story') return `/success/${doc._meta.uid}/`
    if (doc._meta.type === 'career') return `/careers/${doc._meta.uid}/`
    if (doc._meta.type === 'pricing_page') return `/${doc._meta.uid}/`

  } else if(doc.__typename === 'PRISMIC_Homepage'){
    return `/`
    
  } else if (!doc._meta){
    //handling odd handling of 
    if (doc.type === 'post') return `/blog/${doc.uid}/`
    if (doc.uid === "creators-corner" || doc.uid === "kickbooster-news" || doc.uid === "ecommerce-tips" || doc.uid === "crowdfunding-tips" || doc.uid === "case-studies" || doc.uid === "featured-campaigns") return `/blog/${doc.uid}/`
    if (doc.type === 'page') return `/${doc.uid}/`
    if (doc.type === 'find_your_survey') return `/${doc._meta.uid}/`
    if (doc.type === 'success_story') return `/success/${doc.uid}/`
    if (doc.type === 'career') return `/careers/${doc.uid}/`
    if (doc.type === 'pricing_page') return `/${doc._meta.uid}/`
  }

  else{
    // Fallback for other types, in case new custom types get created
    return `/${doc._meta.uid}/`

  }
}

export default linkResolver;
 
