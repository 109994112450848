import React, {Component} from 'react'

class NewsletterForm extends Component{
  
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://kickbooster.activehosted.com/f/embed.php?id=7';
    script.async = true;

    document.body.appendChild(script);
  }

  render(){
    return(
      <div className="hiddenForm">
        <div className="_form_7" />
      </div>
    )
  }
}

export default NewsletterForm
