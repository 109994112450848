
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent, { Cookies } from "react-cookie-consent"
import {RichText} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import './cookieConsent.scss'


const CookieBanner = () => {
  const data = useStaticQuery(graphql`
    {
      prismic {
        allCookie_banners {
          edges {
            node {
              details
              cta_text
              _linkType
            }
          }
        }
      }
    }
  `)

  if(!data.prismic.allCookie_banners.edges[0]){
    return null;
  }

  if(!data.prismic.allCookie_banners.edges[0].node){
    return null;
  }

  const cookieBanner = data.prismic.allCookie_banners.edges[0].node
  
  return (

    <CookieConsent
      location="bottom"
      buttonText={RichText.asText(cookieBanner.cta_text)}
      cookieName="tracking"
      cookieValue="yes"
      declineCookieValue="no"
      onAccept={() => {
        Cookies.set('tracking', 'yes')
        Cookies.set('gatsby-gdpr-google-tagmanager', true)
      }}
      onDecline={() => {
        Cookies.set('tracking', 'no')
        Cookies.set('gatsby-gdpr-google-tagmanager', false)
      }}
      disableStyles={true}
      buttonClasses="kb__button--primary kb__button--large"
      containerClasses="kb__cookie-consent"
      contentClasses="big-body"
      expires={365}
      extraCookieOptions={{ domain: ".kickbooster.com" }}
    >

      <RichText render={cookieBanner.details} linkResolver={linkResolver} /> 

    </CookieConsent>

  )
  
}

export default CookieBanner
